import * as React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
//import Code from "../components/code"
import person from "../images/svg/person-chat.svg"

const Playground = ({ location }) => {

  return (

    <Layout>

      <Seo title="Playground" />
      {/* <div className="page-wrapper"> */}

      <div class="page-header">
          <div class="header-container">


            <div class="header-copy">
              <h1>Playground</h1>
              <p>Things that I’m trying out and can't do on CodePen - Coming soon...</p>
            </div>
            <div class="header-image">
              <img src={person} alt="Person worried" style={{ width: "180px", margin: "0 auto" }} />
            </div>
          </div>
        </div>

    
      <div className="page-body">

        

        <p></p>
        
      </div>
      {/* </div> */}
    </Layout>
  )
}
export default Playground
